import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Sidebar from "./pages/Sidebar";
import Services from "./pages/Services";
import Dashboard from "./pages/Dashboard";
import MyCalendar from "./Components/Calender/Calender";
import StdCalendar from "./Components/Calender/StdCalendar/Calender";
import NewService from "./pages/NewService";
import CreativesList from "./pages/CreativesList";
import BookSessionsForm from "./pages/BookSessionForm";
import MyBookings from "./Components/FromSidebar/Student/MyBookings/MyBookings";
import MyBookingsTutor from "./Components/FromSidebar/Tutor/MyBookings/MyBookingsTutor";
import CalendarSettings from "./pages/CalendarSettings";
import BookSessionFormOne from "./Components/Forms/BookSession/Service/BookSessionFormOne";
import PaymentModel from "./Components/payment/PaymentModel";
import MyRequest from "./pages/MyRequest";
import { useEffect } from "react";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./app.css";
import Home from "./pages/Home";
import ProtectedRoute from "./ProtectedRoute";
import Setting from "./pages/Setting";

function App() {
  window.localStorage.setItem("chakra-ui-color-mode", "dark");

  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const tutorServiceRouteRegex = /^\/tutor\/choose-service\/\d+$/;
  //   const token = localStorage.getItem("currentUser");
  //   if (!token && location.pathname !== "/" && location.pathname !== "/register" && !tutorServiceRouteRegex.test(location.pathname)) {
  //     navigate("/login");
  //   }
  // }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/payment" element={<PaymentModel />} />

      <Route element={<Sidebar />}>
        <Route path="/tutor-search" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/calendar" element={<ProtectedRoute><MyCalendar /></ProtectedRoute>} />
        <Route path="/yourservices" element={<ProtectedRoute><Services /></ProtectedRoute>} />
        <Route path="/creativeslist" element={<ProtectedRoute><CreativesList /></ProtectedRoute>} />
        <Route path="/yourservices/new" element={<ProtectedRoute><NewService /></ProtectedRoute>} />
        <Route path="/mybookings" element={<ProtectedRoute><MyBookings /></ProtectedRoute>} />
        <Route path="/my-requests" element={<ProtectedRoute><MyRequest /></ProtectedRoute>} />
        <Route path="/upcomingsessions" element={<ProtectedRoute><MyBookingsTutor /></ProtectedRoute>} />
        <Route path="/calendarsettings" element={<ProtectedRoute><CalendarSettings /></ProtectedRoute>} />
        <Route path="/book-session/:id" element={<ProtectedRoute><BookSessionsForm /></ProtectedRoute>} />
        <Route path="/calendar/tutor/:tutorId" element={<StdCalendar />} />
        <Route path="/tutor/choose-service/:tutorId" element={<ProtectedRoute><BookSessionFormOne /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/ratesetting" element={<ProtectedRoute><Setting /></ProtectedRoute>} />
      </Route>
    </Routes>
  );
}

export default App;
