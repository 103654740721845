import React, { useState, useEffect } from "react";
import MyRequestHeader from "../Components/Headers/MyRequestHeader.jsx";

import { Flex } from "@chakra-ui/react";
import { API_BASE_URL } from "../apiPaths.js";
import MySessionRequestList from "../Components/Forms/MyRequest/MySessionRequestList.jsx";
import RequestForm from "../Components/Forms/MyRequest/RequestForm.jsx";

export default function MyRequest() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [requestData, setRequestData] = useState([]);
  const [showRequestForm, setShowRequestForm] = useState(false);

  function handleCreateRequest(val) {
    setShowRequestForm(val);
  }

  function handleFormClose(val) {
    setShowRequestForm(val);
  }

  async function fetchMyRequest() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/session-request/${currentUser?.id}`,
        {
          headers: {
            "x-access-token": currentUser.token,
          },
        }
      );
      const res = await response.json();
      setRequestData(res.data[0].data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchMyRequest();
  }, []);
  return (
    <>
      <MyRequestHeader title="My Session Request" onCreateRequest={handleCreateRequest}/>
      <Flex
        flexDirection="column"
        mt={{ base: "20px", md: "40px" }}
        gap="24px"
        maxW={{ base: "90%", md: "55%" }}
        mx="auto">
        {requestData?.map((request) => (
          <MySessionRequestList data={request} key={request._id}/>
        ))}
      </Flex>
      {showRequestForm && (
        <RequestForm onFormClose={handleFormClose} fetchMyRequest={fetchMyRequest}/>
      )}
    </>
  );
}
