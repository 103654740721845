import React, { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react";
import RegisterDetails from "../Components/Forms/RegisterPages/RegisterDetail";
import Register_Profile_One from "../Components/Forms/RegisterPages/RegisterProfileOne";
import Register_Profile_Two from "../Components/Forms/RegisterPages/RegisterProfileTwo";

const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    // console.log(formData);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const steps = [
    {
      content: (
        <RegisterDetails
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
        setFormData={setFormData}
        handleNext={handleNext}
        handlePrevious={handlePrevious} 
        />
      ),
    },
    {
      content: (
        <Register_Profile_One
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
        setFormData={setFormData}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        />
      ),
    },
    {
      content: (
        <Register_Profile_Two
          isLoading={isLoading} 
          setIsLoading={setIsLoading} 
          setFormData={setFormData}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          formData={formData}

        />
      ),
    },
  ];

  return <Box>{steps[activeStep].content}</Box>;
};

export default Register;
