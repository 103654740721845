import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../apiPaths";
import CreativeItem from "../Components/FromSidebar/Student/CreativesList/CreativeItem";
import {
  Box,
  Flex,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

export default function Home() {
  const [userData, setUserData] = useState([]);
  const [isFecth, setIsFetch] = useState(false);

  async function fetchData(q) {
    try {
      const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=${q}`, {
      });
      const users = await response.json();
      setUserData(users.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {

    const element = document.querySelector('body');
    const input = document.querySelector('body .css-1ngklx');
    if (element) {
      element.style.backgroundColor = "#F2F2F2";
      element.style.color = "black";
    }
    
  }, []);

  const handleChange = (event) => { 
    setIsFetch(true);
    fetchData(event.target.value);
  };

  return (
    <Box>
      <Flex
        mb={"20px"}
        flexDirection="column"
        mt={{ base: "20px", md: "40px" }}
        gap="24px"
        maxW={{ base: "90%", md: "85%" }}
        mx="auto">
        <Box
          display={{ base: "none", md: "block" }}
          fontSize="40px"
          color="#2998FF"
          fontWeight="600"
          margin="auto">
          Logo
        </Box>
        <InputGroup borderRadius={5} size="sm" width="50%" margin="auto">
          <Input
            type="text"
            placeholder="Search creative..."
            placeholdertextcolor="red"
            onChange={handleChange}
            border="1px solid black"
            borderWidth="2px"
            borderColor="gray.300"
            borderRadius="10px"
            padding="20px 20px"
            _hover={{
              borderWidth: "2px",
              borderColor: "gray.300",
            }}
            sx={{ "::placeholder": { color: "grey" } }}
          />
          <InputRightElement
            pointerEvents="none"
            padding="20px 20px"
            fontSize="18px"
            children={<Search2Icon color="#2998FF" />}
          />
        </InputGroup>

        {userData?.map((user) => (
          <CreativeItem key={user._id} data={user} />
        ))}
        {(userData.length < 1 && isFecth) ? 
          <Box textAlign="center">Records not found.</Box>
          : null
        }
      </Flex>
    </Box>
  );
}
