import { Box, Button, Center, Flex, Image, VStack , useToast } from "@chakra-ui/react";
import React from "react";
import sessionlogo from "../../../../assests/images/sessionbuilder.png";
import SessionHeader from "../../../Headers/SessionHeader";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL, SERVICE_IMAGE_URL } from "../../../../apiPaths";

export default function NewServiceThree({ handleNext, handlePrevious, newServiceData, formData,isEnoughServices  }) {  
  const toast = useToast();
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));

  const profilePicPreviewUrl = formData.cover_image?.[0]
    ? URL.createObjectURL(formData.cover_image[0])
    : null;

  const onFinish = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/service-builder/`, {
            method: 'POST',
            headers: {
              'x-access-token': currentUser.token 
            },
            body: newServiceData
        });

      if (!response.ok) {
        toast({
          title: "A service with the same name already exists.",
          status: "error",
          duration: 3000,
          isClosable: true
        });
        throw new Error('Network response was not ok');
      }
      toast({
        title: "Service added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/yourservices");
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    } 
  }

  const onUpdate = async () => {
    try {
      newServiceData.id = formData?._id;
      const response = await fetch(`${API_BASE_URL}/service-builder/${formData?._id}`, {
            method: 'PUT',
            headers: {
              'x-access-token': currentUser.token 
            },
            body: newServiceData
        });

      if (!response.ok) {
        toast({
          title: "Error updating service.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      }
      toast({
        title: "Service updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/yourservices");
    } catch (error) {
      console.error('There has been a problem with your operation:', error);
    } 
  }

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/service-builder/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': currentUser.token 
        }      
      });

      if (!response.ok) {
        toast({
          title: "Error deleting service.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      } else {
        toast({
          title: "Service deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/yourservices");
      }
    } catch(error){
      console.error('Error deleting service:', error);
    }
  };

  return (
    <>
      <SessionHeader handlePrevious={handlePrevious}/>
      <Center mt={{ base: "10px", xl: "20px" }}>
        <VStack
          minW={{ base: "100%", md: "640px" }}
          maxW={{ base: "100%", md: "640px" }}
          spacing={{ base: 6, xl: 4 }}
          px={{ base: "15px", md: "0px" }}
          py={{ base: "16px", md: "0px" }}
        >
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            bg="white"
            rounded="8px"
            gap={{ base: "24px", md: "15px" }}
          >
            <Image
              borderRadius="8px 8px 0 0"
              w="100%"
              h="100%"
              // src={profilePicPreviewUrl}
              src={profilePicPreviewUrl || SERVICE_IMAGE_URL+formData?.image}
              crossOrigin="anonymous"
            />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flexDirection={{ base: "column", xl: "row" }}
              px={{ base: "20px", xl: "40px" }}
              gap={{ base: "10px", xl: "20px" }}
            >
              <Center flexDirection="column" gap="6px">
                <Box fontSize="24px" fontWeight="700">
                  {formData?.session_title}
                </Box>
                <Box fontSize="14px" fontWeight="500">
                  £{formData?.rate_price} {formData?.rate_type == 'per_day' ? 'Per Day' : 'Per Hour'}
                </Box>
              </Center>
            </Flex>
            <Flex
              px={{ base: "20px", xl: "40px" }}
              gap={{ base: "10px", xl: "20px" }}
              w="100%"
              pb="40px"
              flexDir="column"
            >
                {/* <Flex gap="4" flexDir="column">
                  <Box fontWeight="600">Question 1</Box>
                  <Box bg="#F2F2F2" px="3" py="2">
                    How many tracks would you like me to review?
                  </Box>
                </Flex>
                <Flex gap="4" flexDir="column">
                  <Box fontWeight="600">Question 2</Box>
                  <Box bg="#F2F2F2" px="3" py="2">
                    How many tracks would you like me to review?
                  </Box>
                  <Flex gap="16px">
                    <Box px="4" py="2" rounded="20px" bg="selectbg">
                      Begineer
                    </Box>
                    <Box px="4" py="2" rounded="20px" bg="selectbg">
                      Expert
                    </Box>
                    <Box px="4" py="2" rounded="20px" bg="selectbg">
                      Advance
                    </Box>
                  </Flex>
                </Flex> */}
                {formData.questionsAnswers.map((questionItem, index) => (
                <Flex key={index} gap="4" flexDir="column">
                  <Box fontWeight="600">{questionItem.question}</Box>
                  {questionItem.answerType === "multipleChoice" ? (
                    <Flex gap="16px">
                      {questionItem.options.split(',').map((option, optionIndex) => (
                        <Box
                          key={optionIndex}
                          px="4"
                          py="2"
                          rounded="20px"
                          bg="selectbg"
                        >
                          {option.trim()} {/* trim to remove leading/trailing whitespace */}
                        </Box>
                      ))}
                    </Flex>
                  ) : (
                    <Box bg="#F2F2F2" px="3" py="2">
                      Client to Add Text Answer
                    </Box>
                  )}
                </Flex>
              ))}

              {!formData?.isService && (
                <Button
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black"
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  py="12px"
                  mt="20px"
                  px="24px"
                  w="100%"
                  fontSize="14px"
                  fontWeight="600"
                  onClick={onFinish}
                >
                  Add to My Services
                </Button>
              )}
               <Button
                  mt="15px"
                  color="black"
                  _hover={{
                    bg: "black",
                    color: "white",
                  }}
                  rounded="40px"
                  w="100%"
                  bg="graybtn"
                  py="12px"
                  fontSize="14px"
                  fontWeight="600"
                  as={Link}
                  to="/yourservices"
                  
                >
                  Cancel
                </Button>
              {formData?.isService && (
                <Button
                  bg="black"
                  _hover={{
                    bg: "white",
                    color: "black",
                    boxShadow: "0 0 3px black"
                  }}
                  color="#F2F2F2"
                  rounded="40px"
                  py="12px"
                  mt="20px"
                  px="24px"
                  w="100%"
                  fontSize="14px"
                  fontWeight="600"
                  onClick={onUpdate}
                >
                  Update
                </Button>
              )}
              {formData?.isService && isEnoughServices && (
                  <Button
                    bg="#E1526C"
                    color="white"
                    fontWeight="bold"
                    rounded="20px"
                    w="full"
                    mt="4"
                    onClick={() => handleDelete(formData?._id)}
                    _hover={{
                      color: "white",
                      bg: "#E1526C",
                    }}
                  >
                    Delete Service
                  </Button>
                )}
            </Flex>
          </Flex>
        </VStack>
      </Center>
    </>
  );
}
