import React from "react";
import { Box, Button, Flex, Image } from "@chakra-ui/react";
import sessionlogo from "../../../../assests/images/sessions.png";
import { Link } from "react-router-dom";
import { SERVICE_IMAGE_URL } from "../../../../apiPaths";
export default function MyBookingList( data ) {
  const booking = data.data;

  return (
    <Flex
      flexDirection={{ base: "column", "2xl": "row" }}
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      bg="white"
      rounded="8px"
      gap={{ base: "24px", md: "10px" }}
    >
      <Image
        height="200px"
        borderRadius={{ base: "8px 8px 0 0", "2xl": "8px 0 0 8px" }}
        w={{ base: "100%", "2xl": "auto" }}
        src={SERVICE_IMAGE_URL + booking?.serviceId?.image}
        crossOrigin='anonymous'
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ base: "column", "2xl": "row" }}
        px={{ base: "20px", "2xl": "40px" }}
        gap={{ base: "20px", "2xl": "40px" }}
      >
        <Flex flexDirection="column" gap="6px">
          <Box fontSize="24px" fontWeight="700">
            { booking?.serviceId?.name } 
          </Box>
          <Box fontSize="14px" fontWeight="400" color="text">
            {booking?.serviceId?.description}
          </Box>
          <Box fontSize="14px" fontWeight="600">
            £{ booking?.serviceId?.rate } { booking?.serviceId?.rateType == 'Per Hour' ? 'Per Hour' : 'Per Day' }
          </Box>
        </Flex>
        <Link>
          <Button
            bg="black"
            _hover={{
              bg: "white",
              border: "1px solid black",
              color: "black",
            }}
            color="#F2F2F2"
            rounded="40px"
            py="12px"
            px="40px"
            w={{ base: "100%", "2xl": "auto" }}
            fontSize="14px"
            fontWeight="600"
            mb={{ base: "30px", "2xl": "0px" }}
          >
            Go To Calendar
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}