import React, { useState } from "react";
import SettingsHeader from "../Components/Headers/SettingsHeader";
import {
    FormControl,
    Input,
    Heading,
    VStack,
    FormLabel,
    Stack,
    Center,
    Switch,
    InputGroup,
} from "@chakra-ui/react";

function Setting() {
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const handleSwitchChange = (e) => {
        setIsSwitchOn(e.target.checked);
    };

    return (
        <>
            <SettingsHeader />
            <Center mt={{ base: "20px", xl: "40px" }}>
                <Stack
                    minW={{ base: "100%", md: "640px" }}
                    spacing={{ base: 6, xl: 12 }}
                >
                    <VStack
                        as="form"
                        bg="#ffffff"
                        color="black"
                        position="relative"
                        borderTopRadius={{ base: "16px", md: "none" }}
                        rounded={{ base: "", md: "lg" }}
                        px={{ base: "15px", md: "24px" }}
                        py={{ base: "40px", md: "32px" }}
                    >
                        <VStack>
                            <Heading size="lg" fontWeight="600">
                                Rate Settings
                            </Heading>
                        </VStack>

                        <VStack spacing={{ base: "15px", md: "20px" }} w="100%">
                            <FormControl mt={4}>
                                <FormLabel>Flat Rate</FormLabel>
                                <Switch
                                    id='rate-setting'
                                    size='lg'
                                    onChange={handleSwitchChange}
                                    sx={{
                                        '& .MuiSwitch-switchBase': {
                                            color: 'grey',
                                            '&.Mui-checked': {
                                                color: 'blue ', 
                                            },
                                        },
                                    }}
                                />
                            </FormControl>

                            {!isSwitchOn && (
                                <FormControl mt="4">
                                    <FormLabel>Your flat rate per hour</FormLabel>
                                    <InputGroup gap="2">
                                        <span className="euro-sign">£</span>
                                        <Input
                                            className="rate-per-hour"
                                            type='text'
                                            borderWidth='1px'
                                            borderColor='gray.300'
                                            _hover={{
                                                borderWidth: '1px',
                                                borderColor: 'gray.300',
                                            }}
                                        />
                                    </InputGroup>
                                </FormControl>
                            )}
                            
                            {isSwitchOn && (
                                <>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions under 1 hour</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 1 hour and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 2 hours and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 3 hours and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl mt="4">
                                        <FormLabel>Your rate for sessions 4 hours and over</FormLabel>
                                        <InputGroup gap="2">
                                            <span className="euro-sign">£</span>
                                            <Input
                                                type="number"
                                                borderWidth="1px"
                                                borderColor="gray.300"
                                                placeholder="per hour"
                                                _placeholder={{
                                                    color: "text",
                                                    fontSize: { base: "12px", md: "14px" },
                                                }}
                                                _hover={{
                                                    borderWidth: "1px",
                                                    borderColor: "gray.300",
                                                }}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </>
                            )}
                        </VStack>
                    </VStack>
                </Stack>
            </Center>
        </>
    );
}

export default Setting;
