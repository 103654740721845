import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import SessionHeader from "../Components/Headers/SessionHeader";
import ServiceItem from "../Components/FromSidebar/Tutor/Service/ServiceItem";
import { API_BASE_URL } from "../apiPaths";

import Slider from "react-slick";

export default function Sessions() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          width: "30px", // Increase the width
          height: "30px",
          position: "absolute",
          borderRadius: "60%",
          top: "-4%",
          left: "90%",
        }}
        onClick={onClick}></div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,

          borderRadius: "50%",
          width: "30px", // Increase the width
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "-4%",
          left: "87%",
        }}
        onClick={onClick}   
      />
    );
  }
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [sessionData, setSessionData] = useState([]);
  const [serviceTemplates, setServiceTemplatesData] = useState([]);
  const toast = useToast();

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const showArrows = sessionData && sessionData.length > 5;
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "30px",
    slidesToShow: 5,
    speed: 500,
    nextArrow: showArrows ? <SampleNextArrow /> : null,
    prevArrow: showArrows ? <SamplePrevArrow /> : null,
    responsive: [
      {
        breakpoint: 1090,
        arrows: false,

        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 868,
        arrows: false,

        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "40px",
        },
      },
    ],
  };

  useEffect(() => {
    // const service = {
    //     session_title : data.name,
    //     description : data.description,
    //     rate_price : data.rate,
    //     rate_type : data.rateType,
    //     image : data.image
    // }

    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function fetchData() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/service-builder/${currentUser.id}`,
        {
          headers: {
            "x-access-token": currentUser.token,
          },
        }
      );
      const sessions = await response.json();
      setSessionData(sessions.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchTemplates() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/service-builder/template/get`,
        {
          headers: {
            "x-access-token": currentUser.token,
          },
        }
      );
      const sessions = await response.json();
      setServiceTemplatesData(sessions.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchTemplates();
    fetchData();
  }, []);

  function refreshServices(isRefresh = false) {
    if(isRefresh) fetchData();
  }

  async function onRemoveClick(serviceId) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/service-builder/${serviceId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentUser.token,
          },
        }
      );

      if (!response.ok) {
        toast({
          title: "Error deleting service.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        toast({
          title: "Service deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        const response = await fetch(
          `${API_BASE_URL}/service-builder/${currentUser.id}`,
          {
            headers: {
              "x-access-token": currentUser.token,
            },
          }
        );
        const sessions = await response.json();
        setSessionData(sessions.data);
      }
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  }

  return (
    <>
    <style>
        {`
          .slick-track {
            display: flex;
          }
          .slick-slide{
            padding: 0 155px;
          }
          .slick-slide div div {
            width: 300px !important;
            max-height: 370px;
          }    
            @media (max-width: 425px) {
            .slick-prev {
            } 

            .slick-next {
            }   

            .slick-slide div div {
              left: 60px !important;
            }  
    
          }   
        `}
      </style>
    <Box>
      <SessionHeader formOne={true} />
      <Box width={"88%"} mx={"auto"} my={5} position={"relative"}>
        <Box mt={5}>
          <Text
            mt={"30px"}
            fontWeight="600"
            fontSize={{ base: "20px", md: "20px" }}>
            My Services
          </Text>
          <Slider {...settings}>
            {sessionData?.map((session) => (
              <Box position={"relative"}>
                <ServiceItem
                  key={session._id}
                  data={session}
                  onRemoveClick={onRemoveClick}
                />
              </Box>
            ))}
          </Slider>
        </Box>
        <Box mt={5}>
          <Text
            fontWeight="600"
            fontSize={{ base: "20px", md: "20px" }}
            mt={"30px"}>
            Service Templates
          </Text>
          <Slider {...settings}>
            {serviceTemplates?.templates?.map((session) => (
              <Box position={"relative"}>
                <ServiceItem
                  key={session._id}
                  data={session}
                  onRemoveClick={onRemoveClick}
                  imageURL ={serviceTemplates.imagePath}
                  refreshServices={refreshServices}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
    </>
  );
}